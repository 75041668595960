import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pl';
import smoothscroll from 'smoothscroll-polyfill';
import { theme } from '../contants/theme';
import './layout.css';
import { GlobalState } from '../state/@types/redux.interface';
import checkIfWindowExist from '../_utils/windowUndefined';
// eslint-disable-next-line import/no-cycle
import AuthorizeLoader from './common/authorize-loader/AuthorizeLoader';

const StyledMain = styled.main`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	width: 100%;
	@media(max-width: 1000px) {
    	min-height: ${({ theme }) => theme.colors.scrollContainerSize};
	}
`;

moment.locale('pl');
checkIfWindowExist(() => smoothscroll.polyfill());

const Layout = ({ children }) => {
	const {
		loginLoading, passwordLoading, codeLoading, isPasswordLoading,
	} = useSelector((state: GlobalState) => state.authorize);
	const { loading: eventLoading } = useSelector((state: GlobalState) => state.event);
	const eventReducer = useSelector((state: GlobalState) => state.event);
	const { data: eventData } = eventReducer;

	return (
		<ThemeProvider theme={theme}>
			<StyledMain>
				{/* eslint-disable-next-line max-len */}
				{ ((loginLoading || eventLoading || codeLoading || passwordLoading || isPasswordLoading) && (eventData !== null)) ? <AuthorizeLoader /> : children}
			</StyledMain>
		</ThemeProvider>
	);
};

export default Layout;

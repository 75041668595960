export const theme = {
	colors: {
		shadow: '"rgba(0, 0, 0, 1)"',
		white: '#FFF',
		greyBar: '#F7F7F8',
		greyBackground: '#E8E8E8',
		veryDarkGrey: '#F4F4F4',
		lightGreyBackground: '#EFEFF4',
		yesNoButtonBorder: '#EBEBEB',
		anotherGrey: '#F8F8F8',
		placeholderColor: '#E2E2E2',
		mediumWhite: '#E3E3E3',
		agendaGrey: '#D1D1D1',
		agendaDarkGrey: '#D6D6D6',
		darkestGrey: '#B6B7B8',
		aqua: '#2BF5BA',
		lime: '#7FE568',
		dirtyAqua: '#D6FFF3',
		softBlue: '#34345C',
		lightBlue: '#191C63',
		blueBackground: '#252478',
		lightPurple: '#A0A0C6',
		purple: '#494981',
		fontBlue: '#07064E',
		buttonBlue: '#0C0B52',
		darkestBlue: '#020139',
		paleBlue: '#B4B4D6',
		fontGrey: '#77838F',
		fontLightGrey: '#C7C7D9',
		lightRed: '#FF8181',
		red: '#F56161',
		darkRed: '#A00000',
		errorOrange: '#F89A7A',
		lightErrorOrange: '#FCDACF',
		yellow: '#FFC989',
		darkYellow: '#FF9C28',
		dirtyYellow: '#F8DAB6',
		orange: '#FFC391',
	},
	sizes: {
		navigationBarHeight: '3.625rem',
		subpageHeaderHeight: '3rem',
		scrollContainerSize: 'calc(100vh - 60px)',
	},
};

import React from 'react';
import styled, { keyframes } from 'styled-components';

const StyledProgressWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.lightGreyBackground};
	border-radius: 4px;
	height: 0.5rem;
	width: 5.188rem;
`;
const progressAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;
const StyledProgressBar = styled.div`
	animation: 2s ${progressAnimation} ease-out infinite;
	background: ${({ theme }) => theme.colors.aqua};
	border-radius: 4px;
	height: 100%;
	width: 0;
`;
const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.blueBackground};
	margin: 10px 0 0;
`;

const ProgressBar = ({ text }: { text: string }) => (
	<StyledProgressWrapper>
		<StyledProgressBar />
		<StyledText>{text}</StyledText>
	</StyledProgressWrapper>
);

export default ProgressBar;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { isIOS } from 'react-device-detect';
import favicon from '../images/sygnet.png';

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl: url
                defaultImage: image
            }
        }
    }
`;

const SEO = ({
	title,
	description,
	image,
	article,
}) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);

	const {
		defaultTitle,
		titleTemplate,
		defaultDescription,
		defaultImage,
		siteUrl,
	} = site.siteMetadata;

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: image || defaultImage,
		url: `${siteUrl}${pathname}`,
	};

	return (
		<Helmet
			defaultImage={defaultImage}
			image={seo.image}
			title={seo.title}
			titleTemplate={titleTemplate}
		>
			<link
				href={favicon}
				rel="icon"
			/>
			<meta
				content={seo.description}
				name="description"
			/>
			<meta
				content={favicon}
				name="image"
			/>
			<meta
				// eslint-disable-next-line
				content={isIOS ? 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1' : 'width=device-width, initial-scale=1, shrink-to-fit=no'}
				name="viewport"
			/>

			{seo.url && (
				<meta
					content={seo.url}
					property="og:url"
				/>
			)}

			{(article ? true : null) && (
				<meta
					content="article"
					property="og:type"
				/>
			)}

			{seo.title && (
				<meta
					content={seo.title}
					property="og:title"
				/>
			)}

			{seo.description && (
				<meta
					content={seo.description}
					property="og:description"
				/>
			)}

			{seo.image && (
				<meta
					content={seo.image}
					property="og:image"
				/>
			)}
		</Helmet>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	article: false,
};
